<template>
  <v-dialog v-model="open" width="500" persistent scrollable>
    <v-card>
      <v-card-title class="title font-weight-bold" primary-title>
        {{ $t("permission.detail_permission") + " : " + data.name }}
        <v-spacer />
        <v-btn small text @click="closeDialog"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-row class="pa-2">
          <v-col cols="12" class="mb-5">
            <div class="font-weight-bold subtitle-1">
              {{ $t("permission.permission_type") }}
            </div>
            <div>
              {{ data.status_izin }}
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <div class="font-weight-bold subtitle-1">
              {{ $t("permission.start_date") }}
            </div>
            <div>
              {{ dateFormat(data.date_in) }}
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <div class="font-weight-bold subtitle-1">
              {{ $t("permission.end_date") }}
            </div>
            <div>
              {{ dateFormat(data.date_out) }}
            </div>
          </v-col>
          <v-col cols="12" class="my-5">
            <div class="font-weight-bold subtitle-1 mb-2">
              {{ $t("permission.description") }}
            </div>
            <div>
              <v-textarea
                :value="data.description"
                hide-details
                disabled
                dense
                auto-grow
                outlined
              />
            </div>
          </v-col>
          <v-col cols="12">
            <div class="font-weight-bold subtitle-1 mb-2">
              {{ $t("permission.attachment") }}
            </div>
            <div>
              <v-img :src="data.file" aspect-ratio="1.5" />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" outlined @click="closeDialog">
          {{ $t("app.back") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment/moment";
moment.locale("id");

export default {
  props: {
    open: Boolean,
    data: Object
  },
  data() {
    return {};
  },
  methods: {
    closeDialog() {
      this.$emit("close");
    },
    dateFormat: date => moment(date).format("DD MMMM YYYY")
  }
};
</script>
